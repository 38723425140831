/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const polarChartData = {
  labels: ["Red", "Green", "Yellow", "Grey", "Blue"],
  datasets: {
    label: "My First Dataset",
    data: [11, 16, 7, 3, 14],
    backgroundColors: ["info", "primary", "dark", "secondary", "success"],
  },
};

export default polarChartData;
